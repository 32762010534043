import React, { Component } from "react"
import scrollToComponent from "react-scroll-to-component"
import _ from "lodash"
import ApolloClient from "apollo-boost"
import gql from "graphql-tag"
import fetch from "isomorphic-fetch"

import { Layout, FaqCard, Filter, Search, SEO } from "../components"
import "./faq.css"

const client = new ApolloClient({
  uri: process.env.API_LIVE_URL,
  fetch,
})

const initialState = {
  data: [],
  currentFilter: "ALL",
  value: "",
  initialData: [],
  currentFilterData: [],
}

export default class Faq extends Component {
  state = initialState
  component
  componentDidMount = async () => {
    const res = await client.query({
      query: gql`
        {
          allFaqs {
            edges {
              node {
                title
                markdownContent
                category
                id
              }
            }
          }
        }
      `,
    })

    await this.setState({
      data: res.data.allFaqs.edges,
      initialData: res.data.allFaqs.edges,
      currentFilterData: res.data.allFaqs.edges,
    })
  }

  componentDidUpdate() {
    const loc = this.props.location.hash
    if (loc === "#RmFxOjY=") {
      scrollToComponent(this.refs.RmFxOjY)
    }
    if (loc === "#RmFxOjI=") {
      scrollToComponent(this.refs.RmFxOjI)
    }
    if (loc === "#RmFxOjEw") {
      scrollToComponent(this.refs.RmFxOjEw)
    }
  }

  changeFilter = filter => {
    this.setState({ currentFilter: filter })

    const filteredData = this.state.initialData.filter(
      item => item.node.category === filter
    )
    this.setState({
      data: filter === "ALL" ? this.state.initialData : filteredData,
      currentFilterData:
        filter === "ALL" ? this.state.initialData : filteredData,
    })
  }

  searchFilter = event => {
    const value = event.target.value
    this.setState({ value })

    setTimeout(() => {
      if (value.length < 1)
        return this.setState({
          data: this.state.currentFilterData,
        })

      const re = new RegExp(_.escapeRegExp(value), "i")

      const isMatchText = result => {
        return re.test(result.node.markdownContent + result.node.title)
      }

      this.setState({
        data: _.filter(this.state.currentFilterData, isMatchText),
      })
    }, 300)
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Вопросы и ответы по использованию сайта проверки штрафов ПДД | OKauto"
          description="Здесь мы собрали список самых популярных вопросов, которые нам задают пользователи сервиса. Развернутые ответы и подробности вы всегда модете получить, задав нам вопрос напрямую в службу поддержки - help@okauto.kz"
        />

        <div className="flex flex-column items-center  tc pv6-ns center faq__wrapper">
          <h1 className="tc f1-l f2-m f3">Вопросы - ответы</h1>
          <div className="w-40-l w-60-m w-90 tl">
            <Search searchFilter={this.searchFilter} />
          </div>
          <div className="flex justify-center w-90">
            <Filter
              changeFilter={this.changeFilter}
              currentFilter={this.state.currentFilter}
            />
          </div>
          <div className="flex flex-wrap faq__cards__wrapper mv5">
            {this.state.data.map(item => (
              <FaqCard
                key={item.node.id}
                header={item.node.title}
                text={item.node.markdownContent}
                id={item.node.id}
                ref={item.node.id.replace(/=/g, "")}
              />
            ))}
          </div>
          <h2 className="f4-ns f5">Не нашли ответ на свой вопрос?</h2>
          <span className="f6-ns f7 gray">
            Напишите нам на почту{" "}
            <a className="link pointer blue">help@okauto.kz</a> или на{" "}
            <a
              className="link pointer blue"
              href="https://api.whatsapp.com/send?phone=77075001122"
              target="_blank"
            >
              Whatsapp
            </a>
          </span>
        </div>
      </Layout>
    )
  }
}
